<template>
  <v-app>
    <!-- header -->
    <v-app-bar
      app
      color="default"
    >
      <div class="d-flex align-center">
        <h3>
          KuCoinToken
        </h3>
      </div>
    </v-app-bar>

    <v-main class="d-flex align-center">
      <calculator />
    </v-main>

    <!-- footer -->
    <v-footer dark padless  fluid>
      <v-card flat tile class="indigo lighten-1 white--text text-center" >
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          All data and information are provided for information purposes only and are not intended for investment or other purposes. This site is not a part of KuCoin or endorsed by KuCoin in any way. This site does not take any responsibility for anything you do with your money as an investment.
          Enjoy and Happy Hodling!
        </v-card-text>

        <v-divider />

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>KuCoinToken.com</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import Calculator from './components/Calculator'

export default {
  name: 'App',

  components: {
    Calculator
  },

  data: () => ({
    icons: ["mdi-twitter",
     
   ]
  })
};
</script>

<style scopped>


.focused input:focus {
  color: #4caf50 !important;
}
</style>
