import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import "./plugins/vuetify-money.js"
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-8HPXHQB3DP" }
});


Vue.use(VueMeta);

Vue.config.productionTip = false


new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')

